<template>
  <div>
    <h1 class="section-header">Filter</h1>
    <div class="box box--p-30 box--outline mt-3">
      <div class="row justify-content-between">
        <div class="select-item col-md-3">
          <label>Välj fordon</label>
          <v-select :clearable="false" class="mt-2" label="licencePlate" :reduce="item => item.licencePlate" v-model="selectedVehicle" :options="vehicles">
            <template #no-options>Inga tillgängliga val.</template>
          </v-select>
        </div>
        <div class="col-md-5 d-flex align-items-end">
          <div class="select-item">
            <label>Tidsintervall</label>
            <date-picker range-separator=" - " range class="mt-2" type="date" v-model="timespan" valueType="format"></date-picker>
          </div>
          <app-button class="btn--secondary ml-4" @click="search">Sök</app-button>
        </div>
        <div class="col-md-4 select-item">
<!--          <label>Save report</label>-->
<!--         <div class="mt-2 d-flex flex-wrap">-->
<!--           <app-button class="btn&#45;&#45;transparent mr-3" icon="pdf-outline">PDF</app-button>-->
<!--           <app-button class="btn&#45;&#45;transparent" icon="excel-outline">Excel</app-button>-->
<!--         </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/shared/AppButton";
import DatePicker from 'vue2-datepicker';
import moment from "moment";
import vehicleHttp from '@/http/vehicles'

export default {
  components: {AppButton, DatePicker},
  data() {
    return {
      boxes: ['19171858490', '19171858489', '19171858488', '19171858499', '19171858495', '19171858497'],
      vehicles: [],
      selectedVehicle: null,
      selectedBox: '19171858488',
      timespan: ['2021-05-07','2021-05-20']
    }
  },
  methods: {
    search() {
      this.$emit('filterChanged', {
        vehicle: this.selectedVehicle,
        date: this.timespan
      })
    },
    async getVehicles() {
      try {
        const {vehicles} = await vehicleHttp.getVehicles({includeDeleted: true})
        this.vehicles = vehicles;
        if(this.vehicles.length === 1) {
          this.selectedVehicle = this.vehicles[0].licencePlate
        }
      } catch (e) { }
    }
  },
  created() {
    this.getVehicles();

    const today = new Date();
    const from = moment(today).subtract(7, 'd').format('YYYY-MM-DD');
    const to = moment(today).format('YYYY-MM-DD');
    this.timespan = [from, to];

    // this.search();
  }
}
</script>

<style scoped>

</style>
